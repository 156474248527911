import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import pixelFinder from '../../../Utils/pixelFinder';
import { getDateFromDateTimeToDisplay, getMonthYearFromDateTimeToDisplay } from '../../../Utils/helper';
import moment from 'moment';



const Grid = ({ age, data, barCodeGen }) => {


    console.log(data, 'data');

    return (
        <>
            <View style={tw("flex flex-row items-center justify-center  ml-2 mt-1")} > <Text style={tw("text-lg w-full text-center mt-2")}>OP Case Sheet </Text>
                <View style={tw("flex flex-col justify-end w-1/4  ml-2 mt-1")} >
                    <View style={tw("flex flex-row justify-center items-center")}>
                        <Image src={barCodeGen} style={tw(`w-40 h-14`)} />
                    </View>
                </View></View>
            <View style={tw("w-full  flex flex-row items-center  ")}>

                <View style={tw("flex flex-col  w-full justify-around h-full  ")}>


                    <View style={tw("flex flex-row justify-start gap-x-2 w-full  border-t-2  border-b-2 border-gray-500 p-2")} >
                        <View style={tw("flex flex-row w-[250px] gap-x-2 ")}>
                            <Text style={[tw("text-sm font-bold  text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}> PatientVisit.No:</Text>
                            <Text style={tw("text-sm flex justify-start text-left ml-1")}>  {data?.docId} </Text>
                        </View>
                        <View style={tw("flex flex-row gap-x-2 w-[200px] ml-1")}>
                            <Text style={[tw("text-sm font-bold  text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Invoice Date:</Text>
                            <Text style={tw("text-sm text-start ml-1")}>{data?.createdAt ? moment(data.createdAt).format("DD-MM-YYYY") : ""}</Text>
                        </View>
                    </View>
                    <View style={tw("flex flex-row justify-start w-full gap-x-2   border-b-2 border-gray-500 p-2")} >
                        <View style={tw("flex flex-row  gap-x-2 w-[250px]")}>
                            <Text style={[tw("text-sm font-bold   text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Reg.No:</Text>
                            <Text style={tw("text-sm font-bold  text-left ml-1")}>{data?.Patient?.regNo || ""}</Text>
                        </View>
                        <View style={tw("flex flex-row gap-x-2 ww-[300px] ml-1")}>
                            <Text style={[tw("text-sm font-bold  text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Dr:</Text>
                            <Text style={tw("text-sm text-left ")}>{data?.Employee?.name || ""}</Text>
                            <Text style={tw("text-sm text-left ")}>{data?.Employee?.degree || ""}</Text>
                        </View>
                    </View>


                    <View style={tw("flex flex-col justify-start w-full   border-b-2 border-gray-500 px-2")} >
                        <View style={tw("flex flex-col  justify-start  w-full  border-b-2 border-gray-500 ")} >

                            <View style={tw("flex flex-row  justify-start  w-full ml-1    p-2")}>      <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                                <Text style={[tw("text-sm font-bold mt-1 "), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Name:  </Text>
                                <Text style={tw("text-sm mt-1")}>{data?.Patient?.name || ""} </Text>
                            </View>
                                <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                                    <Text style={[tw("text-sm font-bold mt-1 "), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Contact: </Text>
                                    <Text style={tw("text-sm mt-1")}>{data?.Patient?.mobile || ""} </Text>
                                </View>
                                <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                                    <Text style={[tw("text-sm font-bold mt-1 "), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Age / Sex:  </Text>
                                    <Text style={tw("text-sm mt-1")}>{data?.Patient?.age || ""} / {data?.Patient?.gender?.charAt(0)}</Text>
                                </View></View>
                            <View style={tw("flex flex-row justify-start  w-full ml-1    p-2 gap-x-2 w-1/2 mb-2")}>
                                <Text style={[tw("text-sm font-bold mt-1"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Address:  </Text>
                                <Text style={tw("text-sm mt-1")}>{data?.Patient?.address || ""}</Text>
                            </View>
                        </View>

                        <View style={tw("flex flex-row  justify-start w-full   mt-1   border-b-2 border-gray-500 p-2")} >

                            <View style={tw("flex flex-row gap-x-2 ")}>
                                <Text style={[tw("text-sm font-bold mt-1 "),
                                { fontWeight: 900, fontFamily: "Times-Bold" }]}>Comments :</Text>
                                <Text style={tw("text-sm mt-1 flex flex-wrap w-3/4")}>{data?.comments || ""} </Text>
                            </View>

                        </View>


                    </View>


                </View>


            </View>
        </>
    )
}

export default Grid
