import React, { useEffect, useState, useRef, useCallback } from 'react';
import secureLocalStorage from 'react-secure-storage';

import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { TextInput, DropdownInput, DisabledInput, DateInput } from "../../../Inputs"
import ReportTemplate from '../../../Basic/components/ReportTemplate';
import {
  useGetPurchaseBillQuery,
  useGetPurchaseBillByIdQuery,
  useAddPurchaseBillMutation,
  useUpdatePurchaseBillMutation,
  useDeletePurchaseBillMutation,
} from '../../../redux/PharmacyServices/PurchaseBillService'

import { dropDownListObject } from '../../../Utils/contructObject';
import { getCommonParams, getDateFromDateTime, isGridDatasValid } from '../../../Utils/helper';
import { useGetPartyQuery } from '../../../redux/PharmacyServices/PartyMasterService';
import { Loader } from '../../../Basic/components';
import PoBillItems from './PoBillItems';
import Modal from "../../../UiComponents/Modal";
import PurchaseBillFormReport from './PurchaseBillFormReport';
import moment from 'moment';
import StoreDropdown from '../../../ReusableComponents/StoreDropdown';
import LocationDropdown from '../../../ReusableComponents/LocationDropdown';
import useSetDefaultLocationStore from '../../../CustomHooks/useSetDefaultLocationStore';
import BarCodePrintFormat from './BarCodePrintFormat';
import useIsBarcodeSystem from '../../../CustomHooks/useIsBarcodeSystem';

const MODEL = "Purchase Bill Entry";

export default function Form() {


  const dateInputRef = useRef(null);
  const today = new Date()
  const [form, setForm] = useState(true);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [address, setAddress] = useState("");
  const [place, setPlace] = useState("");
  const [supplierDcDate, setSupplierDcDate] = useState(new Date());
  const [formReport, setFormReport] = useState(false)
  const [storeId, setStoreId] = useState("");
  const [locationId, setLocationId] = useState("")

  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [supplierDcNo, setSupplierDcNo] = useState("")

  const [netBillValue, setNetBillValue] = useState("")

  const [searchValue, setSearchValue] = useState("");
  const [poBillItems, setPoBillItems] = useState([]);
  const [refetchStoreId, setRefetchStoreId] = useState(false);

  const [barcodePrintOpen, setBarcodePrintOpen] = useState(false);
  const [barCodePerPage, setBarCodePerPage] = useState(1);


  const childRecord = useRef(0);

  const { branchId, finYearId } = getCommonParams()

  function handleInputChange(value, index, field) {
    const newBlend = structuredClone(poBillItems);
    newBlend[index][field] = value;
    setPoBillItems(newBlend);
  };

  const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }

  const { data: allData, isLoading, isFetching } = useGetPurchaseBillQuery({ params: { branchId, finYearId }, searchParams: searchValue });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetPurchaseBillByIdQuery(id, { skip: !id });

  const [addData] = useAddPurchaseBillMutation();
  const [updateData] = useUpdatePurchaseBillMutation();
  const [removeData] = useDeletePurchaseBillMutation();

  const { data: supplierList } =
    useGetPartyQuery({ params });
  console.log(supplierList);


  const getNextDocId = useCallback(() => {

    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false)
      if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
      if (data?.docId) {
        setDocId(data.docId);
      }
      setSupplierDcNo(data?.supplierDcNo ? data?.supplierDcNo : "");
      setSupplierId(data?.supplierId ? data?.supplierId : "");
      setAddress(data?.address ? data.address : "")
      setPlace(data?.place ? data.place : "")
      setPoBillItems(data?.PoBillItems ? data.PoBillItems : []);
      setSupplierDcDate(data?.supplierDcDate ? moment.utc(data?.supplierDcDate).format("YYYY-MM-DD") : new Date());
      setNetBillValue(data?.netBillValue ? data.netBillValue : 0)
      setStoreId(data?.storeId ? data.storeId : '')
      setLocationId(data?.Store?.locationId ? data?.Store?.locationId : '')
      setRefetchStoreId(true)
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  useSetDefaultLocationStore({ setLocationId, setStoreId, refetchStoreId, setRefetchStoreId, id })

  const data = {
    branchId,
    storeId,
    supplierId,
    supplierDcNo,
    supplierDcDate,
    address,
    place,
    netBillValue,
    poBillItems: poBillItems.filter(item => item.productId),
    companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"), id,
    finYearId
  }

  const validateData = (data) => {
    if (data.supplierId && (data.poBillItems.length > 0) && isGridDatasValid(data.poBillItems, false, ['batchNo', 'qty', 'pcsQty', 'expiryDate', 'productId', "uomId", "price", "binId"])) {
      return true;
    }
    return false;
  }

  function getTotal(field1, field2) {
    const total = poBillItems.reduce((accumulator, current) => {

      return accumulator + parseFloat(current[field1] && current[field2] ? current[field1] * current[field2] : 0)
    }, 0)
    return parseFloat(total)
  }


  const validateNetBillValue = () => {

    if (getTotal("qty", "price").toFixed(2) === parseFloat(netBillValue).toFixed(2)) {
      return true;
    }
    return false;
  }



  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId("")
        syncFormWithDb(undefined)
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message)
      }
    } catch (error) {
      console.log("handle")
    }
  }

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (!validateNetBillValue()) {
      toast.info("Net Bill Value Not Matching Total Amount...!", { position: "top-center" })
      return
    }
    // if (!window.confirm("Are you sure save the details ...?")) {
    //   return
    // }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    setId("");
    getNextDocId();
    setReadOnly(false);
    setForm(true);
    setSearchValue("")
    syncFormWithDb(undefined);
  }

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }



  const handleKeyDownForDate = (e) => {

    if (e.key === 'ArrowDown') {

      dateInputRef.current?.showPicker();
    }


    // let newDate = new Date(supplierDcDate);
    // switch (e.key) {
    //   case 'ArrowLeft':
    //     newDate.setDate(newDate.getDate() - 1); // Move back 1 day
    //     break;
    //   case 'ArrowRight':
    //     newDate.setDate(newDate.getDate() + 1); // Move forward 1 day
    //     break;
    //   case 'ArrowUp':
    //     newDate.setDate(newDate.getDate() - 7); // Move back 1 week
    //     break;
    //   case 'ArrowDown':
    //     newDate.setDate(newDate.getDate() + 7); // Move forward 1 week
    //     break;
    //   default:
    //     return;
    // }

    // setSupplierDcDate(newDate);
    // e.preventDefault();
  };

  const tableHeaders = [
    "Code", "Name", "Status"
  ]
  const tableDataNames = ["dataObj.code", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

  const barcodeSystem = useIsBarcodeSystem()

  if (!supplierList) return <Loader />

  if (!form)
    return <ReportTemplate
      heading={MODEL}
      tableHeaders={tableHeaders}
      tableDataNames={tableDataNames}
      loading={
        isLoading || isFetching
      }
      setForm={setForm}
      data={allData?.data}
      onClick={onDataClick}
      onNew={onNew}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />

  return (
    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid bg-theme'>
      <Modal
        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PurchaseBillFormReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>
      <Modal
        isOpen={barcodePrintOpen}
        onClose={() => setBarcodePrintOpen(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <BarCodePrintFormat data={poBillItems.filter(i => i?.productId)} barCodePerPage={barCodePerPage} />
      </Modal>
      <div className='flex flex-col frame w-full'>
        <FormHeader
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onPrint={(barcodeSystem && id) ? () => { setBarcodePrintOpen(true) } : null}
        />
        <div className='flex p-3'>
          <div className='flex flex-col w-full border'>
            <div className='mr-1 w-full'>
              <fieldset className=' my-1 frame'>
                <legend className='sub-heading'>Purchase bill entry</legend>
                <div className='grid grid-cols-6  gap-1 p-2 '>
                  <div className='col-span-1.5'> <DisabledInput name="GRN.No" value={docId} required={true} readOnly={readOnly} /></div>
                  <DisabledInput name="GRN. Date" value={date} type={"Date"} required={true} readOnly={readOnly} />
                  <LocationDropdown selected={locationId} setSelected={(value) => { setLocationId(value); setStoreId("") }} name={"Location"} multiSelect={false} withoutLabel={false} readOnly={readOnly} />
                  <StoreDropdown selected={storeId} setSelected={setStoreId} name={"Store"} multiSelect={false}
                    withoutLabel={false} readOnly={!locationId || readOnly} />
                  <div className='col-span-2 '>
                    <DropdownInput name="Supplier" options={dropDownListObject(id ? supplierList.data : supplierList.data.filter(value => value.isSupplier).filter(item => item.active), "name", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                  </div>

                  <TextInput className='my-2' name="Invoice No." type="text" value={supplierDcNo} setValue={setSupplierDcNo} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                  <TextInput className='my-2' name={"NetBillValue"} value={netBillValue} setValue={setNetBillValue} readOnly={readOnly} required />
                  <div className='mt-2'> <DateInput name="Invoice Date." value={supplierDcDate} setValue={setSupplierDcDate} required={true} readOnly={readOnly} /></div>
                </div>
              </fieldset>
            </div>
            <PoBillItems handleInputChange={handleInputChange} id={id} readOnly={readOnly} poBillItems={poBillItems}
              setPoBillItems={setPoBillItems} singleData={singleData} storeId={storeId} />
          </div>

        </div>
      </div>
    </div>
  )
}
