import React, { useEffect, useState } from 'react'
import MedicineDropdown from './MedicineDropdown'
import { DELETE } from '../../../icons'
import MedicineTimingDropdown from '../../../ReusableComponents/MedicineTimingDropdown'
import { DisabledInput } from '../../../Inputs'
import { useGetProductQuery } from '../../../redux/PharmacyServices/ProductMasterService'
import { PrescriptionTime, TabletCosumptionData } from '../../../ReusableComponents/MedicineCosumptionData'
import StockItemDropdown from '../../../ReusableComponents/StockItemDropdown'
import StockDropdownForPrescription from '../../../ReusableComponents/StockDropdownForPrescription'
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService'
import { getCommonParams } from '../../../Utils/helper'
import { useGetProductBrandQuery } from '../../../redux/PharmacyServices/ProductBrandService'
import MedicineBrandDropdown from './MedicineBrandDropDown'
import secureLocalStorage from 'react-secure-storage'

const MedicationRow = ({ index, readOnly, handleInputChange, handleDeleteRow, row }) => {
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
    const { data: productBrandList } = useGetProductBrandQuery({ params });
    const brandList = productBrandList?.data || [];

    const { data: productList } = useGetProductQuery({
        params: {
            ...params, productBrandId: row.productBrandId,
        }
    });
    const medicineList = productList?.data || [];
    const defaultMedicine = medicineList.length === 1 ? medicineList[0] : null;
    console.log(row, 'row');

    useEffect(() => {
        if (defaultMedicine && !row.productId) {
            handleInputChange(defaultMedicine.id, index, 'productId');
        }
    }, [defaultMedicine, row.productId, handleInputChange, index]);

    const updatedRow = { ...row, productId: row.productId || defaultMedicine?.id };

    const selectedMedicine = medicineList.find(medicine => medicine.id === updatedRow.productId) || {};
    const { branchId, finYearId } = getCommonParams();
    const defaultValue = '';
    const [storeId, setStoreId] = useState(1);

    const { data: singleProduct } = useGetStockQuery({
        params: {
            stockForSales: true,
            productId: selectedMedicine.id,
            stockForPrescription: true,
            storeId,
            branchId
        }
    }, { skip: !(selectedMedicine) });

    const [salePrice, setSalePrice] = useState([]);
    useEffect(() => {
        if (singleProduct && singleProduct?.data) {
            const salesPrices = (singleProduct?.data || []);
            setSalePrice(salesPrices);
        }
    }, [singleProduct]);

    return (
        <tr className="h-full border border-gray-300">
            <td className='border border-gray-300'>
                <MedicineBrandDropdown
                    readOnly={row.patientVisitId}
                    row={updatedRow}
                    index={index}
                    handleInputChange={handleInputChange}
                    brandList={brandList}
                />
            </td>
            {medicineList.length > 1 ? (
                <td className='border border-gray-300'>
                    <MedicineDropdown
                        readOnly={row.patientVisitId}
                        row={updatedRow}
                        index={index}
                        handleInputChange={handleInputChange}
                        medicineList={medicineList}
                    />
                </td>
            ) : (

                <td className='border border-gray-300'>
                    <MedicineDropdown
                        readOnly={row.patientVisitId}
                        row={updatedRow}
                        index={index}
                        handleInputChange={handleInputChange}
                        medicineList={medicineList}
                    />
                </td>
            )}
            <td className='h-full text-[12px] w-[8rem] text-center border border-gray-300'>
                <StockDropdownForPrescription options={salePrice} />
            </td>
            <td className='h-full text-[12px] w-[4rem] text-border border-gray-300'>
                <StockDropdownForPrescription options={salePrice} stock={true} />
            </td>
            <td className=''>
                <input
                    className="border rounded h-[2rem] w-full text-[12px] text-left"
                    type="text"
                    value={selectedMedicine?.productType || defaultValue}
                    readOnly
                />
            </td>

            <td className='border border-gray-300'>
                <input
                    type="text"
                    className="border rounded h-[2rem] w-full text-[12px]  text-center"
                    value={updatedRow.days || defaultValue}
                    disabled={row.patientVisitId}
                    onChange={(event) =>
                        handleInputChange(event.target.value, index, "days")
                    }
                />
            </td>
            <td className='border border-gray-300'>
                <MedicineTimingDropdown
                    readOnly={row.patientVisitId}
                    row={updatedRow}
                    index={index}
                    value={updatedRow.medicineTiming || defaultValue}
                    setValue={(value) => handleInputChange(value, index, 'medicineTiming')}
                />
            </td>

            <td className='border border-gray-300'>
                <input
                    type="text"
                    className="border rounded h-[2rem] w-full text-[12px]  text-center"
                    value={updatedRow.prescriptionTime || defaultValue}
                    disabled={row.patientVisitId}
                    onChange={(event) =>
                        handleInputChange(event.target.value, index, "prescriptionTime")
                    }
                />
            </td>

            <td className='w-14 border border-gray-300'>

                <input
                    type="text"
                    className="border rounded h-[2rem]  text-center text-[12px] w-full"
                    value={updatedRow.M || defaultValue}
                    disabled={row.patientVisitId}
                    onChange={(event) =>
                        handleInputChange(event.target.value, index, "M")
                    }
                />

            </td>

            <td className='w-14 border border-gray-300'>

                <input
                    type="text"
                    className="border rounded h-[2rem]  text-center text-[12px] w-full"
                    value={updatedRow.A || defaultValue}
                    disabled={row.patientVisitId}
                    onChange={(event) =>
                        handleInputChange(event.target.value, index, "A")
                    }
                />

            </td>

            <td className='w-14 border border-gray-300'>

                <input
                    type="text"
                    className="border rounded h-[2rem] text-center text-[12px] w-full"
                    value={updatedRow.E || defaultValue}
                    disabled={row.patientVisitId}
                    onChange={(event) =>
                        handleInputChange(event.target.value, index, "E")
                    }
                />

            </td>

            <td className='w-14 border border-gray-300 '>

                <input
                    type="text"
                    className="border rounded h-[2rem] text-center text-[12px] w-full"
                    value={updatedRow.N || defaultValue}
                    disabled={row.patientVisitId}
                    onChange={(event) =>
                        handleInputChange(event.target.value, index, "N")
                    }
                />

            </td>

            {!readOnly && (
                <td className='border border-gray-300 item-center'>
                    <button className='pl-2' tabIndex={"-1"} onClick={() => handleDeleteRow(index)}>{DELETE}</button>
                </td>
            )}
        </tr>
    );
};

export default MedicationRow;
