import React from 'react';
import { useGetProductQuery } from '../../../redux/PharmacyServices/ProductMasterService';
import { DropdownWithSearch } from '../../../Inputs';

const MedicineDropdown = ({ row, index, handleInputChange, readOnly, medicineList }) => {

    return (
        <div>
            <DropdownWithSearch className={`w-44`} readOnly={readOnly} options={medicineList} value={row.productId} setValue={(value) => handleInputChange(value, index, "productId")} />
        </div>
    )
}

export default MedicineDropdown
