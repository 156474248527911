import React, { Fragment } from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/PharmacyServices/PartyMasterService"
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetSalesBillByIdQuery, useGetSalesBillQuery } from '../../../redux/PharmacyServices/SalesBillService';
import { useGetBarcodeMasterByIdQuery } from '../../../redux/PharmacyServices/BarcodeMasterServices';
import moment from 'moment';
import { TablePagination } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { DateInput } from '../../../Inputs';
import { useGetHsnCodeQuery } from '../../../redux/PharmacyServices/HsnServices';



const AuditorSummary = ({
    onClick,
}) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocId, setSearchDocId] = useState("");
    const [searchBillDate, setSearchBillDate] = useState("");
    const [searchMobileNo, setSearchMobileNo] = useState("");

    const [id, setId] = useState('')
    const [searchCustomerName, setSearchCustomerName] = useState("");
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const [dataPerPage, setDataPerPage] = useState("15");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [searchSupplierDcDate, setSearchSupplierDcDate] = useState("")
    const [currentOpenNumber, setCurrentOpenNumber] = useState('')
    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = { searchDocId, searchBillDate, searchCustomerName, searchMobileNo, searchSupplierDcDate, }

    useEffect(() => { setCurrentPageNumber(0) }, [dataPerPage, searchDocId, searchBillDate, searchCustomerName, searchMobileNo, searchSupplierDcDate])

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const params = {
        branchId, companyId
    };

    const { data: hsnData } = useGetHsnCodeQuery({ params: { auditorSummary: true } });

    const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetSalesBillByIdQuery(id, { skip: !id });
    console.log(singleData, 'singleData');

    const { data: allData, isLoading, isFetching } = useGetSalesBillQuery({
        params: {
            branchId, ...searchFields, auditorSummary: true,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
        }
    });

    const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
        useGetPartyQuery({ params: { ...params } });

    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])

    useEffect(() => {
        let registrationFormReportComponent = document.getElementById("registrationFormReport");
        registrationFormReportComponent.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = document.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            }
        });
        return () => {
            registrationFormReportComponent.removeEventListener('keydown', () => { });
        };
    }, []);

    const isLoadingIndicator = isLoading || isFetching || isSupplierFetching || isSupplierLoading
    const purData = allData?.data ? allData.data : []
    console.log(purData, 'purData');

    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };
    return (
        <>

            <div id='registrationFormReport' className="flex flex-col w-full">
                <div className="flex items-center justify-between w-full page-heading p-1 text-black">
                    <div className="heading text-center whitespace-nowrap flex ">Sales Register</div>
                    <div className="flex items-center text-black justify-end px-2 gap-2  w-full">
                        <label htmlFor="" className="text-white"> Start Date : </label>
                        <DateInput
                            name="Date"
                            value={startDate}
                            setValue={setStartDate}
                            dateFormat="dd-MM-yyyy"
                        />

                        <label htmlFor="" className="text-white"> End Date : </label>
                        <DateInput
                            name="Date"
                            value={endDate}
                            setValue={setEndDate}
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                    <span className='flex gap-4'>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button text-white bg-blue-400 p-2 text-xs "
                            table="table-to-xls"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText='Download to excel'
                        >

                        </ReactHTMLTableToExcel>
                    </span>
                </div>
                <>

                    <div
                        className=" p-4 border m-2"
                    >
                        <table id="" className="table-fixed text-center w-full">
                            <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                <tr className="sticky top-0 font-thin tracking-wider">
                                    <th className="p-2 text-center border border-white font-semibold w-10 px-1">S.no</th>
                                    <th className="p-2 text-center border border-white font-semibold">Bill. No</th>
                                    <th className="p-2 text-center border border-white font-semibold">Bill. Date</th>
                                    <th className="p-2 text-center border border-white font-semibold">Patient Name</th>
                                    <th className="p-2 text-center border border-white font-semibold">Sub Total</th>

                                    {hsnData?.data?.map((tax, index) => (
                                        <Fragment key={`tax-header-${index}`}>
                                            <th className="p-2 text-center border border-white font-semibold">
                                                CGST {tax.CGST}
                                            </th>
                                            <th className="p-2 text-center border border-white font-semibold">
                                                SGST {tax.SGST}
                                            </th>
                                        </Fragment>
                                    ))}

                                    <th className="p-2 text-center border border-white font-semibold">Final Amt</th>
                                </tr>
                            </thead>
                            {isLoadingIndicator ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={8}>
                                            <Loader />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {purData?.map((dataObj, index) => (
                                        <Fragment key={index}>
                                            <tr
                                                className={`table-row ${currentOpenNumber === index ? "border-2 border-green-500" : ""}`}
                                                onClick={() => {
                                                    if (index === currentOpenNumber) {
                                                        setCurrentOpenNumber("");
                                                    } else {
                                                        setCurrentOpenNumber(index);
                                                        setId(dataObj.id);
                                                    }
                                                }}
                                            >
                                                <td className="p-2 text-left border border-white">{(index + 1) + (dataPerPage * currentPageNumber)}</td>
                                                <td className="p-2 text-left border border-white">{dataObj.docId}</td>
                                                <td className="p-2 text-left border border-white">{getDateFromDateTimeToDisplay(dataObj?.userDate)}</td>
                                                <td className="p-2 text-left border border-white">{dataObj.CustomerName}</td>
                                                <td className="p-2 text-right border border-white">{parseFloat(dataObj.subTotal).toFixed(2)}</td>


                                                {hsnData?.data?.map((tax, taxIndex) => {
                                                    const rowTax = dataObj.taxDetails?.[taxIndex];
                                                    const cgstValue = rowTax ? (parseFloat(rowTax.VALUE) / 2).toFixed(2) : "0.00";
                                                    const sgstValue = rowTax ? (parseFloat(rowTax.VALUE) / 2).toFixed(2) : "0.00";
                                                    return (
                                                        <Fragment key={`tax-row-${taxIndex}`}>
                                                            <td className="p-2 text-right border border-white">{cgstValue}</td>
                                                            <td className="p-2 text-right border border-white">{sgstValue}</td>
                                                        </Fragment>
                                                    );
                                                })}

                                                <td className="p-2 text-right border border-white">{parseFloat(dataObj.finalAmount).toFixed(2)}</td>
                                            </tr>
                                        </Fragment>
                                    ))}
                                    <tr className="w-full border h-7 font-bold sticky -bottom-2 z-5">
                                        <td className="text-[15px] pr-2 text-right font-bold" colSpan={4}>Total</td>
                                        <td className="text-[15px] pr-2 text-right font-bold">
                                            {purData?.reduce((acc, curr) => acc + parseFloat(curr.subTotal || 0), 0).toFixed(2)}
                                        </td>

                                        {/* Render totals for each tax type */}
                                        {hsnData?.data?.map((tax, taxIndex) => (
                                            <Fragment key={`tax-total-${taxIndex}`}>
                                                <td className="text-[15px] pr-2 text-right font-bold">
                                                    {/* CGST total (sum of CGST for all rows) */}
                                                </td>
                                                <td className="text-[15px] pr-2 text-right font-bold">
                                                    {/* SGST total (sum of SGST for all rows) */}
                                                </td>
                                            </Fragment>
                                        ))}

                                        <td className="text-[15px] pr-2 text-right font-bold">
                                            {purData?.reduce((acc, curr) => acc + parseFloat(curr.finalAmount || 0), 0).toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>



                        <table id="table-to-xls" className="table-fixed text-center w-full visible">
                            <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                <tr className="sticky top-0 font-thin tracking-wider">
                                    <th className="p-2 text-center border border-white font-semibold w-10 px-1">S.no</th>
                                    <th className="p-2 text-center border border-white font-semibold">Bill. No</th>
                                    <th className="p-2 text-center border border-white font-semibold">Bill. Date</th>
                                    <th className="p-2 text-center border border-white font-semibold">Patient Name</th>
                                    <th className="p-2 text-center border border-white font-semibold">Sub Total</th>

                                    {hsnData?.data?.map((tax, index) => (
                                        <div key={`tax-header-${index}`}>
                                            <th className="p-2 text-center border border-white font-semibold">
                                                CGST {tax.CGST}
                                            </th>
                                            <th className="p-2 text-center border border-white font-semibold">
                                                SGST {tax.SGST}
                                            </th>
                                        </div>
                                    ))}

                                    <th className="p-2 text-center border border-white font-semibold">Final Amt</th>
                                </tr>
                            </thead>
                            {isLoadingIndicator ?
                                <tbody>
                                    <tr>
                                        <td>
                                            <Loader />
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                <tbody>
                                    {purData?.map((dataObj, index) => (
                                        <Fragment key={index}>
                                            <tr
                                                className={`table-row ${currentOpenNumber === index ? "border-2 border-green-500" : ""}`}
                                                onClick={() => {
                                                    if (index === currentOpenNumber) {
                                                        setCurrentOpenNumber("");
                                                    } else {
                                                        setCurrentOpenNumber(index);
                                                        setId(dataObj.id);
                                                    }
                                                }}
                                            >
                                                <td className="p-2 text-left border border-white">{(index + 1) + (dataPerPage * currentPageNumber)}</td>
                                                <td className="p-2 text-left border border-white">{dataObj.docId}</td>
                                                <td className="p-2 text-left border border-white">{getDateFromDateTimeToDisplay(dataObj?.userDate)}</td>
                                                <td className="p-2 text-left border border-white">{dataObj.CustomerName}</td>
                                                <td className="p-2 text-right border border-white">{parseFloat(dataObj.subTotal).toFixed(2)}</td>


                                                {hsnData?.data?.map((tax, taxIndex) => {
                                                    const rowTax = dataObj.taxDetails?.[taxIndex];
                                                    console.log(taxIndex, 'rowTax')
                                                    const cgstValue = rowTax ? (parseFloat(rowTax.VALUE) / 2).toFixed(2) : "0.00";
                                                    const sgstValue = rowTax ? (parseFloat(rowTax.VALUE) / 2).toFixed(2) : "0.00";
                                                    return (
                                                        <div key={`tax-row-${taxIndex}`}>
                                                            <td className="p-2 text-right border border-white">{cgstValue}</td>
                                                            <td className="p-2 text-right border border-white">{sgstValue}</td>
                                                        </div>
                                                    );
                                                })}

                                                <td className="p-2 text-right border border-white">{parseFloat(dataObj.finalAmount).toFixed(2)}</td>
                                            </tr>
                                        </Fragment>
                                    ))}
                                    <tr className="w-full border h-7 font-bold sticky -bottom-2 z-5">
                                        <td className="text-[15px] pr-2 text-right font-bold" colSpan={4}>Total</td>
                                        <td className="text-[15px] pr-2 text-right font-bold">
                                            {purData?.reduce((acc, curr) => acc + parseFloat(curr.subTotal || 0), 0).toFixed(2)}
                                        </td>

                                        {hsnData?.data?.map((tax, taxIndex) => (
                                            <Fragment key={`tax-total-${taxIndex}`}>
                                                <td className="text-[15px] pr-2 text-right font-bold">

                                                </td>
                                                <td className="text-[15px] pr-2 text-right font-bold">

                                                </td>
                                            </Fragment>
                                        ))}

                                        <td className="text-[15px] pr-2 text-right font-bold">
                                            {purData?.reduce((acc, curr) => acc + parseFloat(curr.finalAmount || 0), 0).toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>

                            }
                        </table>
                        <div className='flex items-center justify-end'>
                            <TablePagination
                                component="div"
                                count={totalCount}
                                page={currentPageNumber}
                                onPageChange={handleChange}
                                rowsPerPage={dataPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </>
            </div >
        </>
    )
}

export default AuditorSummary



