import React, { useEffect, useState } from 'react'
import useOutsideClick from '../CustomHooks/handleOutsideClick'
import { getDateFromDateTimeToDisplay, sumArray } from '../Utils/helper'

const StockDropdownForPrescription = ({ options, value, setValue, dropDownOnly = false, intialOpen = false, index, readOnly, stock }) => {
    const [isOpen, setIsOpen] = useState(intialOpen)
    const ref = useOutsideClick(() => {
        setIsOpen(false)
    })
    const headers = ["Exp.Date", "Stock Qty"]
    // const data =
    //     ["expiryDate", "stockQty"]


    let data;
    if (stock) {
        data =
            ["stockQty",]
    } else {
        data =
            ["expiryDate",]
    }



    useEffect(() => {
        const dropDownElement = document.getElementById(`dropdown${index}`);
        dropDownElement.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = dropDownElement.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault()
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault()
            }
        });

        return () => {
            dropDownElement.removeEventListener('keydown', () => { });
        };
    }, [index]);
    function sum(arr) {
        let stock = 0;
        for (let i = 0; i < arr.length; i++) {
            stock += arr[i];
        }
        console.log(arr, 'stock');

        return stock;
    }
    return (
        <div className=' ' id={`dropdown${index}`} ref={ref}>

            <div className=' w-auto  h-full z-100 overflow-auto flex items-center  '>
                <div className='text-sm  w-full text-center flex items-center'>
                    {/* <thead className='w-full sticky z-50 top-0 ' >
                        <tr className='table-row '>
                            {headers.map(item =>
                                <th key={item} className='border-2 border-gray-500'>
                                    {item}
                                </th>
                            )}
                        </tr>
                    </thead> */}
                    <div className='text-center flex flex-col items-center'>
                        {options.map(item =>
                            <tr key={item.stockItem} tabIndex={0} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setValue(item.stockItem)
                                    setIsOpen(false)
                                }
                            }
                            }
                                className='' >
                                {stock ? data.map(i =>
                                    <td key={i} className='table-data h-full text-center border-2 border-gray-500 '>
                                        {i === 'expiryDate'
                                            ?
                                            getDateFromDateTimeToDisplay(item[i])
                                            :
                                            item[i]

                                        }
                                        {console.log(data, 'data')
                                        }
                                    </td>
                                )
                                    : data.map(i =>
                                        <td key={i} className='table-data h-full text-center border-2 border-gray-500 '>
                                            {i === 'expiryDate'
                                                ?
                                                getDateFromDateTimeToDisplay(item[i])
                                                :
                                                item[i]
                                            }
                                        </td>
                                    )
                                }
                            </tr>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default StockDropdownForPrescription