import React from 'react'
import { DropdownInput } from '../Inputs';
import { salesTypesPharmacy } from '../Utils/DropdownData';

const SalesTypePharmacyDropdown = ({ readOnly, name, selected, setSelected, onBlur }) => {
    return (
        <div className='w-36'>  <DropdownInput className='m-2' readOnly={readOnly} name={name} value={selected} setValue={setSelected}
            options={salesTypesPharmacy} onBlur={onBlur} /></div>
    )
}

export default SalesTypePharmacyDropdown
