import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import numToWordConverter from 'num-words';
import { numberFormatter, substract } from '../../../Utils/helper';

const TaxGrid = ({ data }) => {
    const salesItems = data?.SalesBillItems || [];

    const totalQty = salesItems.reduce((a, item) => a + parseFloat(item?.pcsQty || 0), 0);

    const igst = salesItems.reduce((a, item) => {
        const baseAmount = parseFloat(item?.pcsQty || 0) * parseFloat(item?.salePrice || 0);
        return a + (baseAmount * (parseFloat(item?.tax || 0) / 2) / 100);
    }, 0);

    const cgst = salesItems.reduce((a, item) => {
        const baseAmount = parseFloat(item?.pcsQty || 0) * parseFloat(item?.salePrice || 0);
        return a + (baseAmount * (parseFloat(item?.tax || 0) / 2) / 100);
    }, 0);

    const subTotal = salesItems.reduce((a, item) => {
        return a + parseFloat(item?.pcsQty || 0) * parseFloat(item?.salePrice || 0);
    }, 0);

    const totalDiscountAmount = salesItems.reduce((total, item) => {
        return total + (parseFloat(item.discountInAmount) || 0);  // Summing discountInAmount
    }, 0);

    const total = subTotal - (cgst + igst) - totalDiscountAmount;

    const totalAmount = total + igst + cgst;

    const roundedAmount = Math.round(totalAmount);
    const roundOffAmount = Math.abs(substract(totalAmount, roundedAmount));

    return (
        <View wrap={false} style={tw('flex items-center justify-center w-full h-[180px]')}>
            <View style={tw('w-full h-full border-t border-b border-r border-black flex flex-row items-start')}>
                <View style={tw('w-3/4 text-xs')}>
                    {/* Optional Section */}
                </View>
                <View style={tw('h-full flex border-l border-black w-[194px]')}>
                    <View style={tw(' text-[12px] text-center border-b flex flex-row border-black h-20')}>
                        <Text style={tw('w-1/2 border-r border-black text-sm')}>Sub total</Text>

                        <View style={tw('w-1/2 border-r border-black flex flex-row justify-end  items-center')}>
                            <Text style={tw('w-1/2 text-right ')}>{total.toFixed(2)}</Text>
                        </View>
                    </View>
                    <View style={tw(' text-[12px] text-center border-b border-black flex flex-row items-center h-20')}>
                        <Text style={tw('w-1/2 border-r border-black text-sm')}> CGST</Text>
                        <View style={tw('w-1/2 border-r border-black flex flex-row justify-end  items-center')}>
                            <Text style={tw('w-1/2 text-right ')}>{igst.toFixed(2)}</Text>
                        </View>

                    </View>
                    <View style={tw(' text-[12px] text-center border-b border-black flex flex-row items-center h-20')}>
                        <Text style={tw('w-1/2 border-r border-black text-sm')}>SGST</Text>
                        <View style={tw('w-1/2 border-r border-black flex flex-row justify-end  items-center')}>
                            <Text style={tw('w-1/2 text-right ')}>{cgst.toFixed(2)}</Text>
                        </View>

                    </View>
                    {/* Total Discount Amount Display */}
                    <View style={tw('text-[12px] text-center border-b flex flex-row border-black h-20 items-center')}>
                        <Text style={[tw('w-1/2 border-r border-black text-sm items-center'), { fontWeight: 'medium' }]}>Total Discount</Text>
                        <View style={tw('w-1/2 border-r border-black flex flex-row justify-end  items-center')}>
                            <Text >{totalDiscountAmount.toFixed(2)}</Text></View>

                    </View>
                    <View style={tw(' text-[12px] text-center border-b border-black flex flex-row items-center h-20')}>
                        <Text style={tw('w-1/2 border-r border-black text-sm')}>Round Off</Text>

                        <View style={tw('w-1/2 flex flex-row justify-end  items-center')}>
                            <Text>{numberFormatter(roundOffAmount.toFixed(2))}</Text></View>
                    </View>
                    <View style={tw('font-[Times-Bold] text-[12px] text-center border-b border-black flex flex-row items-center h-20')}>
                        <Text style={tw('w-1/2 border-r border-black text-sm')}>GRAND TOTAL</Text>

                        <View style={tw('w-1/2  flex flex-row justify-end  items-center')}>
                            <Text>{numberFormatter(roundedAmount.toFixed(2))}</Text></View>
                    </View>

                </View>
            </View>

            {/* Total Quantity Section */}
            <View style={tw('w-full flex flex-row items-center justify-center text-[10px] border-x border-black h-[30px]')}>
                <Text style={tw('w-[20%] text-center border-r border-black h-full flex flex-row justify-center items-center')}>
                    Quantity
                </Text>
                <Text style={tw('w-[80%] capitalize flex items-center justify-center h-full pl-1')}>{totalQty}</Text>
            </View>

            <View style={tw('w-full flex flex-row items-center justify-center text-[10px] border-x border-black h-[30px]')}>
                <Text style={tw('w-[20%] text-center border-r border-black h-full flex flex-row justify-center items-center')}>
                    Rupees
                </Text>
                <Text style={tw('w-[80%] capitalize flex items-center justify-center h-full pl-1')}>
                    {numToWordConverter(roundedAmount)} Only.
                </Text>
            </View>

            <View style={tw('w-full flex flex-row items-start font-[Times-Bold] p-1 justify underline text-[10px] border border-black h-[60px]')}>
                <View style={tw('w-1/2 h-full border-r border-black text-center')}>
                    <Text>Receiver Signature</Text>
                </View>
                <View style={tw('w-1/2 capitalize text-end flex items-end')}>
                    <Text>For {data?.Store?.locationId}</Text>
                </View>
            </View>
            <View style={tw('w-full text-xs text-center h-[20px]')}>
                <Text style={tw('border-b border-black w-full')}>
                    Items Once Sold, Will not be taken back, Don't take Medicine without doctor consult.
                </Text>
            </View>
        </View>
    );
};

export default TaxGrid;
