import React, { useState, useEffect } from 'react';
import Medication from './Medication';
import LabTest from './LabTest';
import { useGetLabTestTypesQuery } from '../../../redux/pharmaServices/LabTestTypes';
import { getCommonParams } from '../../../Utils/helper';
import { PLUS } from '../../../icons';

const Prescriptions = ({ medicines, setMedicines, prescribedLabTests, setPrescribedLabTests, readOnly = false }) => {
    const [activeTab, setActiveTab] = useState(null); // Default to "MEDICATION"
    const [labTestTypes, setLabTestTypes] = useState([]);
    const { companyId } = getCommonParams();

    const { data, isLoading, isFetching } = useGetLabTestTypesQuery({ params: { active: true, companyId } });
    useEffect(() => {
        if (!data?.data) return;
        setLabTestTypes(data.data);
    }, [data, isLoading, isFetching]);

    const subMenus = [{ name: "MEDICATION", id: null }].concat(labTestTypes);

    const renderActiveContent = () => {
        if (activeTab === null) {
            return (
                <Medication
                    readOnly={readOnly}
                    medicines={medicines}
                    setMedicines={setMedicines}
                />
            );
        }
        return (
            <LabTest
                readOnly={readOnly}
                labTestTypeId={activeTab}
                prescribedLabTests={prescribedLabTests}
                setPrescribedLabTests={setPrescribedLabTests}
            />
        );
    };

    const addRow = () => {
        const newRow = { brand: '', drug: '', unit: "", days: '', dosage: "", M: false, AN: false, EV: false, N: false };
        setMedicines([...medicines, newRow]);
    };


    return (
        <fieldset disabled={readOnly} className="px-4 py-1 space-y-1 w-full">
            <div className='flex   justify-between  '>
                <div className="flex flex-wrap border-b-0 bg-blue-200 bar h-[20%]">
                    {subMenus.map((menu) => (
                        <button
                            key={menu.id}
                            onClick={() => setActiveTab(menu.id)}
                            className={`px-4 py-1 mt-1 mx-1 text-[12px] rounded
                              font-medium ${activeTab === menu.id
                                    ? "bg-white "
                                    : "text-gray-600 hover:bg-blue-50 rounded-b"
                                } transition-all duration-200 ease-in-out`}
                        >
                            {menu.name}
                        </button>
                    ))}
                </div>

            </div>
            <div className="w-full  border rounded-b-lg bg-white shadow-md">
                {renderActiveContent()}
            </div>
        </fieldset>
    );
};

export default Prescriptions;
