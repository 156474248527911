import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../Inputs';
import { dropDownListMultipleShowForStore, multiSelectOption } from '../Utils/contructObject';
import { getCommonParams } from '../Utils/helper';
import { useGetLocationMasterQuery } from '../redux/PharmacyServices/LocationMasterServices';

const StoreDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, locationId, withoutLocation }) => {
    const { token, ...params } = getCommonParams();
    const { data: locationList } = useGetLocationMasterQuery({ params: { ...params, locationId } });
    return (
        <div >
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(locationList ? locationList.data : []).map(item =>
                        <option key={item.id} value={item.id}>{withoutLocation && item?.Location?.name} {item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(locationList ? locationList.data : [], "storeName", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListMultipleShowForStore(locationList ? locationList.data : [], "id")} clear className='my-1' />
                    }
                </>
            }
        </div>
    )
}

export default StoreDropdown
