import React from 'react'
import { DropdownInput } from '../Inputs'

const ProductType = ({ productType, setProductType, readOnly, childRecord }) => {
    const options = [
        { show: 'TABLET', value: 'TABLET' },
        { show: 'CAPSULE', value: 'CAPSULE' },
        { show: 'SYRUP', value: 'SYRUP' },
        { show: 'JELL', value: 'JELL' },
        { show: 'SPRAY', value: 'SPRAY' },
        { show: 'INJECTION', value: 'INJECTION' },
        { show: 'BELT', value: 'BELT' },
        { show: 'SALINE', value: 'SALINE' },
        { show: 'TUBE', value: "TUBE" },
        { show: 'PASTE', value: "PASTE" },
        { show: 'SOAP', value: "SOAP" },
        { show: 'GEL', value: "GEL" },
        { show: 'CREAM', value: "CREAM" },
        { show: 'GRAGCE', value: "GRAGCE" },
        { show: 'BANDAGE', value: "BANDAGE" },
        { show: 'PATCH', value: "PATCH" },
        { show: 'OINTMENT', value: "OINTMENT" },
        { show: 'OIL', value: "OIL" },
        { show: 'SOFT ROLL', value: "SOFTROLL" },
        { show: 'POP', value: "POP" },
        { show: 'PLASTER', value: "PLASTER" },
        { show: 'SHEET', value: "SHEET" },
        { show: 'CHAIR', value: "CHAIR" },
        { show: 'STICK', value: "STICK" },
        { show: 'CHOCHOLATE', value: "CHOCHOLATE" },
        { show: 'URINE CAN', value: "URINECAN" },
        { show: 'URINE BAG', value: "URINEBAG" },
        { show: 'POP', value: "POP" },
        { show: 'ORTHO BELT', value: "ORTHOBELT" },
        { show: 'ROLLER', value: "ROLLER" }

    ]
    return (
        <DropdownInput name="Product Type" options={options} value={productType} setValue={setProductType} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
    )
}

export default ProductType