import React, { useRef, useState, useEffect } from 'react';
import TaxDropdown from '../../../ReusableComponents/taxDropdown';
import { useGetBranchQuery, useUpdateManyBranchMutation } from '../../../redux/services/BranchMasterService';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { EditButtonOnly } from '../../../Buttons';

const TaxStatus = () => {
    const [readOnly, setReadOnly] = useState(false);
    const [branches, setBranches] = useState([]);
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem('sessionId') + 'userCompanyId') };

    const { data: branchesData, isLoading } = useGetBranchQuery(params);
    const [updateMany] = useUpdateManyBranchMutation();

    useEffect(() => {
        if (branchesData) {
            setBranches(
                branchesData?.data?.map((branch) => ({
                    ...branch,
                    taxStatus: branch.taxStatus || 'INCLUSIVETAX',
                })) || []
            );
        }
    }, [branchesData]);

    const handleTaxStatusChange = (value, index) => {
        setBranches((prevBranches) =>
            prevBranches.map((branch, i) =>
                i === index ? { ...branch, taxStatus: value } : branch
            )
        );
    };

    const saveBranchDetails = async () => {
        try {
            await updateMany({ branches, companyId: params.companyId });
            toast.success('Settings Saved', { position: 'top-center' });
        } catch (error) {
            toast.error('Failed to save settings', { position: 'top-center' });
        }
    };

    const childRecord = useRef(0);

    return (
        <div className="flex flex-col justify-center p-4">
            <h1 className="text-xl font-semibold mb-4">Select Tax Status</h1>
            {isLoading ? (
                <p>Loading branches...</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {branches.map((branch, index) => (
                        <div key={branch.id} className="p-4 border rounded shadow">
                            <h2 className="font-semibold">{branch.name}</h2>
                            <TaxDropdown
                                taxStatus={branch.taxStatus}
                                setTaxStatus={(value) => handleTaxStatusChange(value, index)}
                                readOnly={readOnly}
                                childRecord={childRecord}
                            />
                            <div className="text-sm flex justify-end">
                                <EditButtonOnly onClick={() => setReadOnly(true)} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="flex justify-end mt-4">
                <button
                    onClick={saveBranchDetails}
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default TaxStatus;
