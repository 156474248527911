import React, { useEffect } from 'react';
import { PLUS } from '../../../icons';
import MedicationRow from './MedicationRow';


const Medication = ({ medicines, setMedicines, readOnly }) => {


    const handleInputChange = (value, index, field) => {

        setMedicines(prev => {
            const newMedicines = structuredClone(prev);
            newMedicines[index][field] = value;

            return newMedicines
        });
    };

    useEffect(() => {
        if (readOnly) return
        else {
            if (medicines.length === 0) {
                setMedicines([{
                    brand: '', drug: '', unit: "",
                    days: '', dosage: "", M: '', AN: '', EV: '', N: ''
                }]);
            }
        }
    }, [medicines, readOnly])

    const addRow = () => {
        const newRow = { brand: '', drug: '', unit: "", days: '', dosage: "", M: '', AN: '', EV: '', N: '', };
        setMedicines([...medicines, newRow]);
    };
    const handleDeleteRow = id => {
        const filteredRows = medicines.filter((row, index) => index !== id);
        setMedicines(filteredRows);
    };
    const onKeyDown = (e) => {
        if (e.key === "Tab") {
            addRow();
        }
    };
    useEffect(() => {
        if (medicines.length >= 1) return
        setMedicines(prev => {
            let newArray = Array.from({ length: 9 }, i => {
                return {
                    brand: '', drug: '', unit: "",
                    days: '', dosage: "", M: '', AN: '', EV: '', N: ''
                }
            })
            return [...prev, ...newArray]
        }
        )
    }, [setMedicines, medicines])
    return (
        <div className='flex flex-col'>

            {
                medicines.length !== 0 ?
                    <div className='overflow-y-scroll ' >
                        {!readOnly && (
                            <div className="flex justify-end">
                                <button
                                    onClick={addRow}
                                    className="flex items-center px-1 text-[12px] text-white bg-green-600 rounded hover:bg-green-700 h-5"
                                >
                                    {PLUS}
                                    <span className="">Add</span>
                                </button>
                            </div>
                        )}
                        <table className=" text-[14px] border border-gray-300 ">
                            <thead className='bg-gray-800'>
                                <tr className='text-[12px] font-medium border  border-gray-300 text-white'>
                                    <th className="font-medium w-[15rem]  p-1 border border-gray-300 ">Product Brand
                                    </th>
                                    <th className="font-medium w-[15rem]  border border-gray-300">Product
                                    </th>
                                    <th className=" font-medium w-[4rem] border border-gray-300">Exp Date</th>
                                    <th className=" font-medium w-[6rem] border border-gray-300">Stock</th>
                                    <th className=" font-medium w-[6rem] border border-gray-300">Type</th>
                                    {/* <th className=" border font-medium w-20 border-gray-300">Unit </th> */}
                                    <th className=" font-medium w-[6rem] border border-gray-300"  >
                                        <div className='grid grid-cols-2 '>
                                            <div className='col-span-2'>
                                                Days
                                            </div>
                                        </div>
                                    </th>
                                    <th className=" font-medium w-22 border ">Time</th>
                                    <th className=" font-medium w-[8rem] border "  >
                                        <div className='grid grid-cols-2'>
                                            <div className='col-span-2 '>
                                                Pres Time
                                            </div>
                                        </div>
                                    </th>
                                    <th className=" font-medium w-[4rem] border "  >
                                        <div className='grid grid-cols-2 '>
                                            <div className='col-span-2 '>
                                                M
                                            </div>
                                        </div>
                                    </th>
                                    <th className=" font-medium w-[4rem] border " >
                                        <div className='grid grid-cols-2  '>
                                            <div className='col-span-2 '>
                                                AN
                                            </div>
                                        </div>
                                    </th>
                                    <th className=" font-medium w-[4rem] border" >
                                        <div className='grid grid-cols-2 '>
                                            <div className='col-span-2 '>
                                                E
                                            </div>
                                        </div>
                                    </th>
                                    <th className=" font-medium w-[4rem] border " >
                                        <div className='grid grid-cols-2  '>
                                            <div className='col-span-2 '>
                                                N
                                            </div>
                                        </div>
                                    </th>
                                    <th className=" font-medium w-[4rem] border " >
                                        <div className='grid grid-cols-2  '>
                                            <div className='col-span-2 '>
                                                Del
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='border border-gray-300 h-full w-full '>
                                {medicines.map((row, index) => (
                                    <MedicationRow key={index} row={row} handleDeleteRow={handleDeleteRow} handleInputChange={handleInputChange} index={index} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div></div>
            }
        </div>
    )
}

export default Medication
