import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import kmLogo from "../../../assets/hospital.png";
import { ADDRESS, APP_NAME, CITY_ADDRESS, GST_IN, MEDICAL } from '../../../Strings';

const Header = () => {
    const logoSize = '100';
    return (
        <View style={tw("w-full ")}>
            <View style={tw("flex flex-row items-center w-full justify-between ")}>
                <View style={tw("flex flex-row justify-center items-center")}>
                    <Image src={kmLogo} style={tw(`w-[${logoSize}px] `)} />
                </View>
                <View style={tw("w-[100%] flex flex-row justify-center items-center")}>

                    <View style={tw("flex flex-col justify-center items-center")}>
                        <Text style={tw(" text-[15px] text-gray-800 text-center font-[Times-Bold]")} >{MEDICAL}</Text>
                        <Text style={tw(" text-[9px]")} >{ADDRESS}</Text>
                        <View style={tw("flex flex-row gap-1 justify-center items-center")} >
                            <Text style={tw("text-[9px]")} >{CITY_ADDRESS}</Text>
                            <Text style={tw("text-[9px]")} >{GST_IN}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Header
