import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useGetPatientQuery, useGetPatientByIdQuery } from '../../../redux/pharmaServices/PatientMaster';
import { useGetCityQuery } from "../../../redux/services/CityMasterService"
import {
  useGetPatientVisitQuery,
  useGetPatientVisitByIdQuery,
  useAddPatientVisitMutation,
  useUpdatePatientVisitMutation,
  useDeletePatientVisitMutation
} from "../../../redux/pharmaServices/PatientVisitTransaction";
import { useGetEmployeeByIdQuery, useGetEmployeeQuery } from '../../../redux/services/EmployeeMasterService';
import { FormHeader, FormReport } from '../../../Basic/components';
import { toast } from "react-toastify"
import { DisabledInput, TextInput, TextArea, DropdownInput, DateInput, validateMobile, NumberInput } from '../../../Inputs';
import { dropDownListMergedObject, dropDownListObject } from '../../../Utils/contructObject';
import { genderList, maritalStatusList, bloodList } from '../../../Utils/DropdownData';
import Modal from "../../../UiComponents/Modal"
import io from 'socket.io-client';
import moment from 'moment';
import { getCommonParams, getDateAndTimeFromDateTimeToDisplay, getDateFromDateTimeToDisplay, getTimeFromDateTimeToDisplay } from '../../../Utils/helper';
import SingleImageFileUploadComponent from '../../../Basic/components/SingleImageUploadComponent';
import LiveWebCam from '../../../Basic/components/LiveWebCam';
import useOutsideClick from '../../../CustomHooks/handleOutsideClick';
import { PDFViewer } from '@react-pdf/renderer';


import { useDispatch, useSelector } from 'react-redux';
import { push } from '../../../redux/features/opentabs';
import { PAYMENT_PAGE_NAME } from '../../../Constants';
import tw from '../../../Utils/tailwind-react-pdf';
import Token from './Token';
import PrintFormatViewer from '../../../Basic/components/PrintFormt/PrintFormatViewer';

const MODEL = "Patient Visit";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const socket = io.connect(BASE_URL);
const date = new Date()


export default function Form() {

  const openTabs = useSelector((state) => state.openTabs);
  const previewId = useMemo(() => openTabs.tabs.find(i => i.name === "PATIENT VISIT TRANSACTION")?.previewId, [openTabs]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [form, setForm] = useState(false);
  const [searchPatientName, setSearchPatientName] = useState("")
  const [id, setId] = useState("")
  const [docId, setDocId] = useState("");
  const [docDate, setDocDate] = useState("");
  const [active, setActive] = useState(true);
  const [hideNavBar, sethideNavBar] = useState(true);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState(null);
  const [fatherName, setFatherName] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [token, setToken] = useState("");
  const ref = useOutsideClick(() => sethideNavBar(true));
  const [regNo, setregNo] = useState("");
  const [mobile, setMobile] = useState("");
  const [isTax, setIsTax] = useState(false);

  const [address, setAddress] = useState("");
  const [pincode
    , setPincode] = useState("");
  const [city, setCity] = useState("");
  const [age, setAge] = useState('')
  const [gender, setGender] = useState("");
  const [image, setImage] = useState("");
  const [reasonForVisit, setReasonForVisit] = useState("");
  const [bp, setBp] = useState("");
  const [sugar, setSugar] = useState("");
  const [pulse, setPulse] = useState("");
  const [temperature, setTemperature] = useState("");
  const [weight, setWeight] = useState("");
  const [consultFee, setConsultFee] = useState("");
  const [isConsultFeePaid, setIsConsultFeePaid] = useState(false);
  const [searchDate, setSearchDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [prescriptionFormat, setPrescriptionFormat] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [referredDoctor, setReferredDoctor] = useState('')
  const [pdfUrl, setPdfUrl] = useState(null);
  const [doctor, setDoctor] = useState("");
  const { finYearId, branchId, companyId } = getCommonParams();

  const dispatch = useDispatch()
  const reportName = 'patient-case-Report.mrt'
  const parameter = `visitId=${id}`
  useEffect(() => {
    if (!previewId) return
    setId(previewId);
    dispatch(push({ name: "PATIENT VISIT TRANSACTION", previewId: null }))
  }, [previewId, dispatch])

  const params = { companyId }

  const { data: patientList } = useGetPatientQuery({ params: { branchId, mobile, finYearId } });
  console.log(patientList, 'patientList');

  const childRecord = useRef(0);


  const { data: cityList } = useGetCityQuery({ ...params, branchId, active: true });
  const { data: allData, isLoading, isFetching } = useGetPatientVisitQuery({ params: { ...params, searchPatientName: searchPatientName, date: searchDate, prevPatientVisit: true } });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetPatientVisitByIdQuery(id, { skip: !id });

  const { data: patientData, isLoading: isPatientLoading, isFetching: isPatientFetching } = useGetPatientByIdQuery(patientId, { skip: id && !patientId });

  const { data: doctorList } = useGetEmployeeQuery({ params: { branchId, employeeCategory: "DOCTOR", active: true } });

  const [addData] = useAddPatientVisitMutation();
  const [updateData] = useUpdatePatientVisitMutation();
  const [removeData] = useDeletePatientVisitMutation();

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data?.docId);
      }
      setDocDate(getDateAndTimeFromDateTimeToDisplay(data?.createdAt || new Date()));
      setToken(data?.token ? data.token : "");
      setBp(data?.bp ? data.bp : "");
      setSugar(data?.sugar ? data.sugar : "");
      setPulse(data?.pulse ? data.pulse : "");
      setTemperature(data?.temperature ? data.temperature : "");
      setWeight(data?.weight ? data.weight : "");
      setReasonForVisit(data?.reasonForVisit ? data.reasonForVisit : "");
      setDoctor(data?.employeeId ? data.employeeId : "");
      setConsultFee(data?.PatientVisitPayment?.consultFee ? data?.PatientVisitPayment?.consultFee : (data?.consultFee || ''));
      setIsConsultFeePaid(data?.PatientVisitPayment?.isConsultFeePaid || false);
      setIsTax(data?.isTax || false);
      // Patient Data
      setPatientId(data?.patientId ? data.patientId : "");
      setDob(data?.Patient?.dob ? moment.utc(data?.Patient.dob).format('YYYY-MM-DD') : "");
      setName(data?.Patient?.name ? data?.Patient?.name : "");
      setregNo(data?.Patient?.regNo ? data?.Patient?.regNo : "");
      setMobile(data?.Patient?.mobile ? data?.Patient.mobile : "");
      setFatherName(data?.Patient?.fatherName ? data?.Patient.fatherName : "");
      setGender(data?.Patient?.gender ? data?.Patient.gender : "");
      setMaritalStatus(data?.Patient?.maritalStatus ? data?.Patient.maritalStatus : "");
      setBloodGroup(data?.Patient?.bloodGroup ? data?.Patient.bloodGroup : "");
      setAddress(data?.Patient?.address ? data?.Patient.address : "");
      setPincode(data?.Patient?.pincode
        ? data?.Patient.pincode
        : "");
      setCity(data?.Patient?.cityId ? data?.Patient.cityId : "");
      setImage(data?.Patient?.image || "");
      setAge(data?.Patient?.age ? data?.Patient.age : "");
      setReferredDoctor(data?.referredDoctorId || '')
      // setImage(data?.Patient?.imageBase64 ? viewBase64String(data?.Patient.imageBase64) : "");
    }, [id])

  console.log(docDate, 'docDate');


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  const { data: docData } = useGetPatientVisitQuery({ params: { finYearId, branchId, isGetNextDocId: true, isTax } });


  const getNextDocId = useCallback(() => {
    if (id) return
    if (docData?.data?.docId) {
      setDocId(docData?.data?.docId)
    }
  }, [docData, id])

  useEffect(getNextDocId, [getNextDocId])

  const getNextRegNo = useCallback(() => {
    if (id) return
    if (patientData?.data.regNo) {
      setregNo(patientData?.data.regNo)
    } else {
      setregNo(patientList?.regNo)
    }
  }, [patientData, patientList, id])
  useEffect(getNextRegNo, [getNextRegNo])

  const syncFormPatientDetails = (data) => {

    // Patient Data
    setDob(data?.dob ? moment.utc(data?.dob).format('YYYY-MM-DD') : "");
    setName(data?.name || '');
    setregNo(data?.regNo || '');
    setMobile(data?.mobile || '');
    setFatherName(data?.fatherName || '');
    setGender(data?.gender || '');
    setMaritalStatus(data?.maritalStatus || '');
    setBloodGroup(data?.bloodGroup || '');
    setAddress(data?.address || '');
    setPincode(data?.pincode
      ? data.pincode
      : "");
    setCity(data?.cityId ? data.cityId : "");
    setImage(data?.image || "");
    sethideNavBar(true);
    setAge(data?.age || '')
  }
  useEffect(() => {
    // if (id) return
    if (patientId && patientData?.data) {
      syncFormPatientDetails(patientData?.data);
    } else {
      syncFormPatientDetails(undefined);
    }
  }, [patientId, patientData, isPatientLoading, isPatientFetching, id])

  const { data: doctorData } = useGetEmployeeByIdQuery(doctor, { skip: !doctor })

  const updateConsultFee = useCallback(() => {
    if (!doctorData?.data) return
    setConsultFee(doctorData?.data?.consultFee || 0);
  }, [doctorData])

  useEffect(() => {
    if (id) return
    updateConsultFee()
  }, [updateConsultFee, id])

  const validateData = (data) => {
    if (data.name && data.fatherName && data.gender && data.maritalStatus &&
      data.mobile) {
      return true;
    }
    return false;
  }

  const data = {
    branchId,
    name, fatherName, dob, age,
    address, city, pincode
    , mobile, gender, maritalStatus, bloodGroup, active, patientId, consultFee, isConsultFeePaid
  }

  const patientVisitData = {
    bp, sugar, pulse, temperature, weight, doctor, reasonForVisit, id, finYearId, isTax
  }


  const handleSubmitCustom = async (callback, data, text) => {
    try {

      const formData = new FormData()
      const out = { ...data, ...patientVisitData };

      for (let key in out) {
        formData.append(key, out[key]);
      }
      if (image instanceof File) {
        formData.append("image", image);
      } else if (!image) {
        formData.append("isImageDeleted", true);
      }
      if (text === "Updated") {
        await callback({ id, body: formData });
        onNew()
      } else {
        const returnedData = await callback(formData).unwrap()
        setId(returnedData?.patientVisit?.id);
      }

      socket.emit(`newPatient`, { doctorId: doctor });
      dispatch({
        type: `Patient/invalidateTags`,
        payload: ['Patient'],
      });
      dispatch({
        type: `Payment/invalidateTags`,
        payload: ['Payment'],
      });
      // toast.success(text + "Successfully", { autoClose: 100, position: 'top-center' });
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", {
        position: "top-center",

      })
      return
    }
    if (!patientVisitData?.doctor) {
      toast.info("Doctor is Required...!")
      return
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }

  }

  const onNew = () => { syncFormWithDb(undefined); setId(""); setReadOnly(false); setForm(true); }

  const tableHeaders = ["Token", "Patient Name", "PhoneNumber", "Doctor"]
  const tableDataNames = ["dataObj?.token", "dataObj?.Patient?.name", "dataObj?.Patient?.mobile", "dataObj?.Employee?.name"]


  const dropDownDataOnclick = (localPatientId) => {
    setPatientId(localPatientId);
  }

  const handlePatientClick = (patient) => {
    // Logic for what happens when a patient is clicked
    console.log('Selected Patient:', patient);
    setMobile(patient.phone); // Set the mobile input to the selected patient phone
    setShowDropdown(false); // Close the dropdown
    setPatientId(patient.id); // Set the selected patient ID
  };
  useEffect(() => {
    socket.on('connect', () => {
      console.log("connected")
    });

    socket.on('disconnect', () => {
      console.log("disconnect")
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
    }
  }, [])

  const handleInputChange = (e) => {
    setMobile(e.target.value);

    if (validateMobile(e.target.value)) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleFocus = () => {
    setShowDropdown(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };

  return (
    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid  h-[90vh]  border border-gray-200 p-3 '>
      <Modal isOpen={prescriptionFormat} onClose={() => setPrescriptionFormat(false)} widthClass={"w-[90%] h-[90%]"}>
        <PDFViewer style={tw("h-full w-full")} >
          <Token data={singleData?.data || {}} />
        </PDFViewer>
        {/* <PrintFormatViewer pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} id={id} reportName={reportName} parameter={parameter} /> */}
      </Modal>
      <Modal isOpen={cameraOpen} onClose={() => setCameraOpen(false)}>
        <LiveWebCam picture={image} setPicture={setImage} onClose={() => setCameraOpen(false)} />
      </Modal>
      <div className='flex flex-col frame w-full h-full'>
        <FormHeader onNew={onNew} onPrint={id ? () => { setPrescriptionFormat(true) } : null} model={MODEL} saveData={saveData} setReadOnly={setReadOnly} deleteData={deleteData} childRecord={childRecord.current} />
        <div className='flex w-[100%] 
        '>
          <div className=' w-full flex   '>
            <fieldset className='  border-2 border-grey-200 flex flex-col w-[40%] p-2 m-1'>
              <div className='text-center  text-blue-900  text-[14px] font-bold'>PATIENT DETAILS</div>
              <fieldset className="frame  flex border border-gray
               rounded-lg p-2 justify-center items-center ">
                <legend className="text-blue-900 text-sm sub-heading px-2">PERSONAL INFO</legend>

                <div
                  ref={ref}
                  onClick={() => sethideNavBar(!hideNavBar)}
                  className="flex flex-col justify-center w-full  px-2  "
                >
                  {/* Mobile Number Input */}
                  <div className="flex w-full ">
                    <div className="w-[60%]">
                      <input
                        name="Mobile No"
                        type="number"
                        value={mobile}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        placeholder="Type Patient Mob No"
                        className="border p-2 text-gray-700 border-gray-300 shadow-lg rounded 
                        h-7 px-2 font-medium text-[14px] text-center  focus:outline-none focus:ring focus:ring-blue-500"
                        autoFocus
                      />
                    </div>
                    <div className="flex items-center">

                      <input type="text" className='text-gray-800 border border-gray-300 shadow-lg text-[14px] rounded h-7 w-44 px-2' tabIndex={-1} value={regNo} placeholder='Patient Id' />
                    </div>
                  </div>

                  {showDropdown && validateMobile(mobile) && (
                    <div className="absolute z-10 bg-white border border-gray-300 rounded-md overflow-auto mb-10
                     p-1 w-56 shadow-md ">
                      {patientList.data.map((patient) => (
                        <div
                          key={patient.id}
                          className="text-sm cursor-pointer hover:bg-gray-200
                           border-b border-gray-200 text-blue-600  last:border-none"
                          onClick={() => handlePatientClick(patient)}
                        >
                          {patient.name}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Text Inputs */}
                  <div className="flex w-full gap-1  ">
                    <div className="w-full flex  flex-col">
                      <TextInput
                        name="Patient Name"
                        type="text"
                        value={name}
                        setValue={setName}
                        required={true}
                        readOnly={readOnly}
                        className="flex-grow my-3"

                      />
                      <TextInput
                        name="Father Name"
                        type="text"
                        value={fatherName}
                        setValue={setFatherName}
                        required={true}
                        readOnly={readOnly}
                        className="flex-grow my-3"

                      /></div>
                    <div className=" flex flex-col gap-3">
                      <div className='w-[6rem]'>
                        <TextInput
                          name="Age"
                          type="text"
                          value={age}
                          setValue={setAge}
                          required={true}
                          readOnly={readOnly}
                          className="flex-grow my-3"
                        /></div>
                      <div className='w-[6rem] '>
                        <DateInput
                          name="DOB"
                          value={dob}
                          setValue={setDob}
                          readOnly={readOnly}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <DropdownInput className='w-full my-2'
                      name="Gender"
                      options={genderList}
                      value={gender}
                      setValue={setGender}
                      required={true}
                      readOnly={readOnly}

                    />
                    <DropdownInput className='w-full my-2'
                      name="Marital Status"
                      options={maritalStatusList}
                      value={maritalStatus}
                      setValue={setMaritalStatus}
                      required={true}
                      readOnly={readOnly}
                    />
                    <DropdownInput className='w-full my-2'
                      name="Blood Group"
                      options={bloodList}
                      value={bloodGroup}
                      setValue={setBloodGroup}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
                {/* Image Upload Section */}
                <div className="border border-gray-300 rounded-md p-2  
                flex justify-center items-center bg-white h-40">
                  <SingleImageFileUploadComponent
                    size="120px"
                    setWebCam={setCameraOpen}
                    disabled={readOnly}
                    image={image}
                    setImage={setImage}
                  />
                </div>
              </fieldset>
              <fieldset className='frame  w-full p-1 mt-2 shadow-lg rounded-md '>
                <legend className='text-blue-900 text-[13px] sub-heading px-2 '>ADDRESS DETAILS</legend>
                <div className='w-[100%] flex flex-col '>
                  <div className='w-[100%] '>
                    <TextArea name="Address" value={address} setValue={setAddress} readOnly={readOnly} />
                  </div>

                  <div className='grid grid-cols-2  pr-2'>
                    <NumberInput name="Pincode" type="number" value={pincode} setValue={setPincode} readOnly={readOnly}
                      className='my-2' />
                    <DropdownInput className='w-full my-1'
                      name="City"
                      options={dropDownListMergedObject(cityList ? cityList.data : [])}
                      value={city}
                      setValue={setCity}
                      readOnly={readOnly}
                    />

                  </div>

                </div>
              </fieldset>


            </fieldset>
            <div className='border-2 border-gray-200 flex flex-col w-[32%]  m-1'>
              <div className='text-center  text-blue-900  text-[14px] font-bold uppercase'>Visit Details</div>
              <div className='grid grid-cols-1 shadow-lg'>
                <fieldset className=' my-1 m-2 flex flex-col gap-1 frame shadow-lg rounded-md'>
                  <legend className='text-blue-900 text-[14px] uppercase sub-heading px-2'>Health Details</legend>
                  <div className='flex flex-col gap-2'>
                    <div className='grid grid-cols-5 px-2 gap-2 '>
                      <TextInput name="BP" type="text" value={bp} setValue={setBp} readOnly={readOnly} className="flex-grow"
                      />

                      <TextInput name="Sugar " type="text" value={sugar} setValue={setSugar} readOnly={readOnly} />
                      <TextInput name="Pulse" type="text" value={pulse} setValue={setPulse} readOnly={readOnly} />
                      <TextInput name="Temp" type="text" value={temperature} setValue={setTemperature} readOnly={readOnly} />
                      <TextInput name="Wt" type="text" value={weight} setValue={setWeight} readOnly={readOnly} />
                    </div>
                    <div className='mt-2'>
                      <TextArea name="Reason For Visit" type="text" value={reasonForVisit} setValue={setReasonForVisit} readOnly={readOnly} />
                    </div></div>
                </fieldset>
                <fieldset className='my-1 m-2 flex flex-col gap-1 frame shadow-lg rounded-md'>
                  <legend className='text-blue-900 text-[13px] sub-heading px-2'>Doctor to be Visited</legend>
                  <div className='flex w-full flex-col items-end  justify-between p-2'>
                    <div className='flex pb-2 w-full gap-3 '>
                      <DropdownInput className='w-full' name="Doctor Name" options={dropDownListObject(doctorList ? doctorList?.data : "", "name", "id")} value={doctor} setValue={setDoctor} readOnly={readOnly} />
                      <div className=''>
                        <TextInput name="Fees" classNameInput="text-left" type="number"
                          className={"text-left"} min={0} value={consultFee} setValue={setConsultFee} readOnly={readOnly} disabled />
                      </div>
                      <div className='flex h-full rounded 
                    '>
                        <button className=' bg-green-600 hover:bg-green-800 h-7 px-1  rounded
                       text-white
                      ' onClick={() => {
                            dispatch(push({ name: PAYMENT_PAGE_NAME, previewId: id }))
                          }}>
                          Payment
                        </button>
                      </div>
                      {referredDoctor ? <DropdownInput className='w-full' name="Referred Doctor" options={dropDownListObject(doctorList ? doctorList?.data : "", "name", "id")} value={referredDoctor} setValue={setDoctor} readOnly={readOnly} /> : ''}
                    </div>
                  </div>
                </fieldset>
                <fieldset className='frame m-2  flex-1 flex flex-col justify-between text-xl  shadow-lg rounded'>
                  <legend className='text-blue-900 text-[13px] sub-heading px-2'>Token</legend>
                  <div className='flex items-center justify-center '>
                    <div className=' flex flex-col items-center justify-center p-3'>
                      <div className='text-center text-xxl border-gray-800 flex items-center
                       rounded-full px-6 p-1 h-[56]'>
                        {token ? <div> <p className='text-center text-xxl p-2 font-semibold'>
                          <span className=' border border-gray-800 rounded p-3 
                             bg-green-600 text-white text-2xl'>  {token} </span>
                        </p>    <div className='p-3'>{docDate} </div></div> :
                          ""}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className='frame overflow-x-hidden w-[28%] border-2 border-gray-200 m-1'>

              <FormReport
                searchPatientName={searchPatientName}
                searchDate={searchDate}
                setSearchDate={setSearchDate}
                setSearchPatientName={setSearchPatientName}
                setId={setId}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                data={allData?.data || []}
                loading={
                  isLoading || isFetching
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
