import React, { useEffect, useState, useCallback, useRef } from 'react';
import secureLocalStorage from 'react-secure-storage';
import {
    useGetPatientVisitQuery,
    useGetPatientVisitByIdQuery,
    useUpdatePatientVisitMutation,
    useAddPatientVisitMutation
} from '../../../redux/pharmaServices/PatientVisitTransaction';

import DoctorDashBoard from '../DoctorDashboard';
import moment from 'moment';
import Modal from '../../../UiComponents/Modal';
import PatientHistoryDetail from './PatientHistoryDetail';
import { CloseButton, PrintButtonOnly, SaveButton } from '../../../Buttons';
import { calculateAge, getCommonParams } from '../../../Utils/helper';
import { bloodList } from '../../../Utils/DropdownData';
import SingleImageFileUploadComponent from '../../../Basic/components/SingleImageUploadComponent copy';
import LastVisitDetails from './LastVisitDetails';
import Prescriptions from './Prescriptions';
import { CheckBox, DateInput, DisabledInput, DropdownInput, TextArea, TextInput } from '../../../Inputs';
import { toast } from 'react-toastify';
import { PDFViewer } from '@react-pdf/renderer';
import tw from "../../../Utils/tailwind-react-pdf";
import PrintFormatInvoice from '../DoctorConsultation/PrintFormatInvoice';
import { useGetEmployeeByIdQuery, useGetEmployeeQuery } from '../../../redux/services/EmployeeMasterService';
import PrintFormatDoctorConsult from '../PrintFormatDoctorConsult';
import { dropDownListObject } from '../../../Utils/contructObject';
import styled from "styled-components";
import TextareaAutosize from 'react-textarea-autosize';

const ACTIVE = (
    <button className="rounded bg-green-500 border border-green-300 p-1 disabled">
        ACTIVE
    </button>
);
const INACTIVE = (
    <button className="rounded bg-red-500 border border-red-300 p-1 disabled">
        INACTIVE
    </button>
);
const BLOOD_LIST = bloodList;
const MOMENT = moment;

export default function Form() {

    const [dateFilter, setDateFilter] = useState(new Date());
    const [id, setId] = useState("")
    const [print, setPrint] = useState(false);
    const [barCode, setBarCode] = useState("")
    const [currentPatientVisitIdForPopup, setCurrentPatientVisitIdForPopup] = useState("")
    const [prescribedLabTests, setPrescribedLabTests] = useState([]);
    const [patientId, setPatientId] = useState("");

    const [medicines, setMedicines] = useState([]);
    const [comments, setComments] = useState("");
    const [readOnly, setReadOnly] = useState(false);
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [token, setToken] = useState("");
    const [activeNavBar, setActiveNavBar] = useState(null);
    const [regNo, setregNo] = useState("");
    const [mobile, setMobile] = useState("");
    const [image, setImage] = useState("");
    const [reasonForVisit, setReasonForVisit] = useState("");
    const [bp, setBp] = useState("");
    const [sugar, setSugar] = useState("");
    const [pulse, setPulse] = useState("");
    const [temperature, setTemperature] = useState("");
    const [weight, setWeight] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [consulted, setConsulted] = useState(false);
    const [referDoctor, setReferDoctor] = useState(false)
    const [considerPrimaryDoc, setConsiderPrimaryDoc] = useState("");
    const [doctor, setDoctor] = useState("");
    const [reviewDate, setReviewDate] = useState("");
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
    const userId = secureLocalStorage.getItem(sessionStorage.getItem('sessionId') + 'employeeId');
    console.log(userId, 'userId');
    const { data: allData, refetch: refetchPatientVisit } = useGetPatientVisitQuery({ params: { ...params, date: moment.utc(dateFilter).format("YYYY-MM-DD"), consulted, userId } });
    const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetPatientVisitByIdQuery(id, { skip: !id });
    const brand = singleData?.data.Prescriptions
    const { data: asPerPatient } = useGetPatientVisitQuery({ params: { patientId } });
    const [updateData] = useUpdatePatientVisitMutation();
    const TextArea = styled.textarea`
    padding: 2px;
    resize: none;
    overflow-y: hidden;
  `;
    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount);
        }
    }, [allData])
    useEffect(() => {
        if (!reviewDate) return
        if (reviewDate < new Date()) {
            toast.error('Review Date cannot be less than current Date')
            setReviewDate('')
        }
    }, [reviewDate])
    const syncFormWithDb = useCallback(
        (data) => {
            if (!data) return
            if (id) setReadOnly(true);
            setReadOnly(true);
            setToken(data.token);
            setBp(data.bp);
            setSugar(data.sugar);
            setPulse(data.pulse);
            setTemperature(data.temperature);
            setWeight(data.weight ? data.weight : "");
            setReasonForVisit(data.reasonForVisit);
            // Patient Data
            setDob(data.Patient.dob ? moment.utc(data.Patient.dob).format('YYYY-MM-DD') : "");
            setName(data.Patient.name);
            setPatientId(data.Patient.id);
            setregNo(data.Patient.regNo);
            setMobile(data.Patient.mobile);
            setBloodGroup(data.Patient.bloodGroup);
            setImage(data.Patient.image ? (data.Patient.image) : "");
            setMedicines(data.Prescriptions.filter(item => item.employeeId === userId));
            setPrescribedLabTests(data.PrescribedLabTest.map(test => parseInt(test.LabTest.id)));
            setComments(data.comments ? data.comments : "");
            setDoctor(data.referredDoctorId || '')
            setReviewDate(data.reviewDate ? moment.utc(data.reviewDate).format('YYYY-MM-DD') : '')
        }, [id, userId])

    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

    const validateData = (data) => {
        console.log(data, 'data');

        const prescriptions = JSON.parse(data?.prescriptions || "[]");

        if (prescriptions.length > 0) {
            const isValid = prescriptions.every(prescription => prescription?.productId);
            if (!isValid) {
                console.log("Invalid prescription data");
            }
            return isValid;
        }

        console.log("No prescriptions found");
        return false;
    }

    const { branchId, finYearId } = getCommonParams()
    const empId = singleData?.data?.employeeId ? singleData.data.employeeId : undefined;
    const prevPatientVisitId = singleData?.data?.prevPatientVisitId ? singleData.data.prevPatientVisitId : undefined;
    const data = {
        comments, prescriptions: JSON.stringify(medicines.filter(m => m.productId)), labTests: JSON.stringify(prescribedLabTests.map(test => { return { labTestId: test } })), consulted: true, id, patientId, branchId, finYear: finYearId,
        bp, sugar, pulse, temperature, weight: weight ? parseInt(weight) : undefined,
        reasonForVisit, doctor, referDoctor, considerPrimaryDoc, prevPatientVisitId, employeeId: userId, reviewDate

    }
    const referredDoctorId = singleData?.data?.referredDoctorId ? singleData.data.prevPatientVisitId : undefined;
    const { data: empData } = useGetEmployeeByIdQuery(empId, { skip: !empId });
    const { data: doctorData } = useGetEmployeeQuery({ params: { branchId, employeeCategory: "DOCTOR", active: true } });

    const doctorList = doctorData?.data ? doctorData.data.filter((item) => item.id !== userId) : [];

    const textAreaRef = useRef(null);


    // useEffect(resizeTextArea, [comments]);

    const saveData = async () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return
        }
        const formData = new FormData();
        for (let index = 0; index < Object.keys(data).length; index++) {
            const key = Object.keys(data)[index];
            const value = data[key];
            formData.append(key, value);
        }



        await updateData({ id, body: formData }).unwrap();

        toast.success("Updated Successfully");
    }
    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();


        if (event.key === 'Enter') {
            // Allow default behavior of new line insertion
            return true;
        }
        if ((event.ctrlKey || event.metaKey) && charCode === 's') {
            event.preventDefault();
            saveData();
        }
    }


    const tableHeaders = ["Date", "Reason"]
    const tableDataNames = ["createdAt", "reasonForVisit"];
    if (!id)
        return <DoctorDashBoard patientVisits={allData?.data ? allData?.data : []} dateFilter={dateFilter} setDateFilter={setDateFilter} refetchPatientVisit={refetchPatientVisit}
            totalCount={totalCount} currentPageNumber={currentPageNumber} setCurrentPageNumber={setCurrentPageNumber} dataPerPage={dataPerPage} setDataPerPage={setDataPerPage} setId={setId} setConsulted={setConsulted} consulted={consulted} />


    let patientHistory = asPerPatient?.data ? asPerPatient?.data : [];

    return (
        <div className=' w-full'>
            <Modal isOpen={print} onClose={() => { setPrint(false) }} widthClass={"w-[90%] h-[90%]"} >
                <PDFViewer style={tw("w-full h-full")}>
                    <PrintFormatDoctorConsult brand={brand} age={dob ? calculateAge(new Date(dob)) : ""} barCode={barCode} setBarCode={setBarCode} data={singleData?.data ? singleData.data : undefined} />
                    {/* <PrintFormatInvoice data={singleData?.data ? singleData.data : undefined} empData={empData?.data ? empData.data : undefined} /> */}
                </PDFViewer>
            </Modal>
            <div className='w-full px-2' onKeyDown={handleKeyDown}>
                <Modal isOpen={Number.isInteger(currentPatientVisitIdForPopup)} onClose={() => setCurrentPatientVisitIdForPopup("")} widthClass={"w-5/6"}>
                    <PatientHistoryDetail patientVisitId={currentPatientVisitIdForPopup} />
                </Modal>
                <div className='flex md:items-center md:justify-between page-heading w-full' style={{ height: "5%" }}>
                    <div className='heading text-center md:mx-10'>Doctor Consultation</div>
                    <div className='text-sm px-1'>
                        <SaveButton onClick={saveData} />
                        <CloseButton onClick={() => { setId("") }} />
                        {singleData?.data?.consulted &&
                            <PrintButtonOnly onClick={() => setPrint(true)} />
                        }
                    </div>
                </div>
                <div className='border  w-full flex' >
                    <div className='w-1/2 flex flex-col h-full'>
                        <div className={`h-[60%] ${(patientHistory.length > 1) ? " float-left border " : "w-full"}`}>
                            <div className='sub-heading text-[14px]'>Patient Details </div>
                            <div class="relative">
                            </div>
                            <div className={`flex flex-col  gap-1 `}>
                                <fieldset className='frame flex flex-col px-2 shadow-lg '>
                                    <legend className=' text-blue-900 text-[14px] '>Patient Info</legend>

                                    <div className={`flex items-center justify-center  rounded-lg  bg-white gap-2`}>
                                        <SingleImageFileUploadComponent editProfileImage={false} disabled={readOnly} image={image} setImage={setImage} imageHeight='100px' imageWidth='100px' barcode={regNo} />
                                        <div className=''> <DisabledInput className='my-3' name="Patient id" type={"text"} value={regNo} />

                                            <DisabledInput className='my-3' name="Mobile No" type="number" value={mobile} />

                                            <DisabledInput className='my-3' name="Name" type="text" value={name} />
                                            <div className='flex gap-2'>  <DisabledInput name="Age" type="text" value={dob ? calculateAge(new Date(dob)) : ""} />
                                                <DisabledInput name="Token no" type={"text"} value={token} />
                                                <DropdownInput name="B-Group" options={bloodList} value={bloodGroup} setValue={setBloodGroup} readOnly={readOnly} className='w-24' /></div>
                                        </div>
                                    </div>


                                    <div className=' flex w-full my-2'>
                                        <div className='flex justify-between items-center '>

                                            <div className='w-15 '>
                                                {!prevPatientVisitId ? <DateInput name="Review After" type="text" value={reviewDate} setValue={setReviewDate} /> : ''}
                                            </div>
                                            <div className='w-20 '><CheckBox name="Refer " value={referDoctor} setValue={setReferDoctor} /></div>
                                        </div>

                                        <div className='flex w-full'>{!prevPatientVisitId ?
                                            <DropdownInput
                                                className='w-full ' name="Doctor Name" options={dropDownListObject(doctorList ? doctorList : "", "name", "id")} value={doctor} setValue={setDoctor} readOnly={referDoctor ? !readOnly : readOnly} />
                                            : ''}
                                        </div>


                                        <div className='flex items-center'> {prevPatientVisitId ? < CheckBox name="Consider With Primary Doctor " value={considerPrimaryDoc} setValue={setConsiderPrimaryDoc} />
                                            : ''}</div>
                                    </div>



                                </fieldset>
                                <fieldset className='frame '>
                                    <legend className=' text-blue-900 text-[14px]'>Health details</legend>
                                    <div className=' flex-col h-[50%]' >
                                        <div className='flex gap-2 px-2'>
                                            <TextInput name="BP" type="text" value={bp} setValue={setBp} />
                                            <TextInput name="Sugar" type="text" value={sugar} setValue={setSugar} />
                                            <TextInput name="Pulse" type="text" value={pulse} setValue={setPulse} />

                                            <TextInput name="Temp" type="text" value={temperature} setValue={setTemperature} />
                                            <TextInput name="Weight" type="number" value={weight} setValue={setWeight} />
                                        </div>
                                    </div>
                                    {/* <TextArea name="Reason For Visit" type="text" value={reasonForVisit} setValue={setReasonForVisit} /> */}
                                    <div className='mt-5'>       <label htmlFor="comments" className='px-2 text-[13px]'>Reason </label>
                                        <textarea
                                            ref={textAreaRef}
                                            rows="5"
                                            cols="30"
                                            name="Reason"
                                            className="w-full px-2 mb-4 h-8 focus:outline-none border border-gray-500 rounded text-[12px]"
                                            value={reasonForVisit}
                                            onChange={(e) => setReasonForVisit(e.target.value)}
                                            onKeyDown={handleKeyDown} /></div>
                                </fieldset>


                            </div>
                        </div>
                        {/* {patientHistory.length > 1 ?
                            <div className="w-1/4 float-right border">
                                <LastVisitDetails patientVisitId={patientHistory.at(1).id}
                                    viewFullDetail={() => { setCurrentPatientVisitIdForPopup(patientHistory.at(1).id) }} />
                            </div>
                            :
                            <div></div>
                        } */}
                        <div className='w-full h-[40%]'>
                            <div className='text-center bg-gray-200 text-blue-900 text-[14px]'
                            >Patient History</div>
                            <div className='flex h-[12rem] overflow-y-scroll '>
                                <table className='text-center w-full'>
                                    <thead className='border-2 table-header sticky top-0'>
                                        <tr>
                                            {tableHeaders.map((head, index) => <th key={index} className='border-2 sticky top-0 stick-bg text-[12px]'>{head}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody className='border-2'>
                                        {(patientHistory).filter(item => parseInt(item.id) !== parseInt(id)).map((dataObj, index) =>
                                            <tr key={index} className='border-2 table-row' onClick={() => { setCurrentPatientVisitIdForPopup(dataObj.id) }}>
                                                {tableDataNames.map((data, index) => (
                                                    <td key={index} className='table-data p-1 '>
                                                        {data === "createdAt"
                                                            ? moment(dataObj[data]).format('YYYY-MM-DD')
                                                            : dataObj[data]}
                                                    </td>
                                                ))}



                                            </tr>
                                        )}.
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='w-full ' >
                        <div className='w-full float-left  border '>
                            <div className='text-left sub-heading text-blue-900 text-[14px]' >Prescription Details</div>
                            <div className=' flex flex-col justify-between h-full'>

                                <div className='h-[85%]'> <Prescriptions medicines={medicines} setMedicines={setMedicines} prescribedLabTests={prescribedLabTests} setPrescribedLabTests={setPrescribedLabTests} /></div>

                                <div className='flex flex-col h-[15%]'>
                                    <div>  <label htmlFor="comments" className='px-2 text-[14px]'>Comments </label></div>
                                    <div className='flex px-2'>
                                        <textarea
                                            ref={textAreaRef}
                                            rows="5"
                                            cols="40"
                                            name="comments"
                                            className="w-full px-2 h-10 focus:outline-none border border-gray-500 rounded text-[14px]"
                                            value={comments}
                                            onChange={(e) => setComments(e.target.value)}
                                            onKeyDown={handleKeyDown} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
