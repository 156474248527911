import React from 'react'
import { DropdownInput } from '../Inputs'

const TaxDropdown = ({ taxStatus, setTaxStatus, readOnly, childRecord }) => {
    const options = [
        { show: 'INCLUSIVE TAX', value: 'INCLUSIVETAX' },
        { show: 'EXCLUSIVE TAX', value: 'EXCLUSIVETAX' },
    ]
    return (
        <DropdownInput name="Tax Satus" options={options} value={taxStatus} setValue={setTaxStatus} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
    )
}

export default TaxDropdown