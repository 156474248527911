import React from 'react';
import { useGetProductQuery } from '../../../redux/PharmacyServices/ProductMasterService';
import { DropdownWithSearch } from '../../../Inputs';

const MedicineBrandDropdown = ({ row, index, handleInputChange, readOnly, brandList }) => {

    return (
        <div >
            <DropdownWithSearch className={`w-44`} readOnly={readOnly} options={brandList} value={row.productBrandId} setValue={(value) => handleInputChange(value, index, "productBrandId")} />
        </div>
    )
}
export default MedicineBrandDropdown
