import React, { useEffect, useState } from 'react'
import useOutsideClick from '../CustomHooks/handleOutsideClick'
import { getDateFromDateTimeToDisplay } from '../Utils/helper'

const StockItemOption = ({ options, value, setValue, dropDownOnly = false, intialOpen = false, index, readOnly }) => {
    const [isOpen, setIsOpen] = useState(intialOpen)
    const ref = useOutsideClick(() => {
        setIsOpen(false)
    })
    console.log(options, 'options');

    const headers = ["Price", "Bin", "Rack", "Bat.no", "Exp.Date", "Supplier", "Stock Qty"]
    const data = ["salePrice", "Bin", "Rack", "batchNo", "expiryDate", "supplierName", "stockQty"]
    useEffect(() => {
        const dropDownElement = document.getElementById(`dropdown${index}`);
        dropDownElement.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = dropDownElement.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault()
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault()
            }
        });

        return () => {
            dropDownElement.removeEventListener('keydown', () => { });
        };
    }, [index]);
    return (
        <div className='w-auto   bg-gray-100' id={`dropdown${index}`} ref={ref}>
            {!dropDownOnly &&
                <input readOnly tabIndex={0}
                    onFocus={() => setIsOpen(false)}
                    onKeyDown={(e) => {
                        if (e.key === "ArrowDown") {
                            setIsOpen(true)
                        } else if (e.key === "ArrowUp") {
                            setIsOpen(false);
                        }
                    }} onClick={() => { if (!readOnly) setIsOpen(true) }} value={value} className='w-full border border-gray-300 rounded z-0' />
            }
            {(!readOnly) && isOpen &&
                <div className='absolute w-auto  h-full z-100 overflow-auto max-h-[100px]  '>
                    <table className='text-sm w-full border-2 border-gray-500'>
                        <thead className='w-full sticky z-50 top-0 ' >
                            <tr className='table-row '>
                                {headers.map(item =>
                                    <th key={item} className='border-2 border-gray-500'>
                                        {item}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {options.map(item =>
                                <tr key={item.stockItem} tabIndex={0} onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setValue(item.stockItem)
                                        setIsOpen(false)
                                    }
                                }
                                }
                                    onClick={() => { setValue(item.stockItem); setIsOpen(false); }} className='table-row' >
                                    {data.map(i =>
                                        <td key={i} className='table-data h-full text-center border-2 border-gray-500'>
                                            {i === 'expiryDate'
                                                ?
                                                getDateFromDateTimeToDisplay(item[i])
                                                :
                                                item[i]
                                            }
                                        </td>
                                    )
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default StockItemOption